.App {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.content {
  flex: 1 0 auto;
}

.footer {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.footer-main-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 2px solid #f1701b;
}

.App-header {
  background-color: #f1701b;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 50px;
}

.new-line {
  white-space: pre-line;
}

.title {
  font-size: large;
  font-weight: bold;
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.button {
  color: #f87721 !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #ffffff;
  padding: 8px;
  border: 4px solid #f87721 !important;
  display: inline-block;
  border-radius: 8px;
  transition: all 0.4s ease 0s;
  font-weight: bold;
}

.button:hover {
  color: #ffffff !important;
  background: #f6b93b;
  border-color: #f6b93b !important;
  transition: all 0.4s ease 0s;
}

.ticketIframe {
  border: 0; 
  width: 100%; 
  height: 100%;
}